'use client';

import { FC } from 'react';

import Carousel from '@components/shared/carousel/Carousel';
import { breakpointsHighlight } from '@lib/constants/breakpointsSwiper';
import { GAMStorageService } from '@services/GAMStorageService';
import { IProduct } from '@types';
import { HighlightProductCard } from './highlight-product-card/HighlightProductCard';

import classes from './HighlightProducts.module.scss';

interface IProps {
  title?: string;
  products: IProduct[] | undefined;
}

const HighlightProducts: FC<IProps> = ({ products, title }) => {
  if (!products) return null;

  const onCardClick = (id: string, index: number) => {
    GAMStorageService.setToStore({ [id]: { index: index + 1, item_list_name: title || 'Всі' } });
  };

  const productCards = products.map((p, idx) => (
    <HighlightProductCard
      onCardClick={onCardClick}
      key={p.displayCode}
      index={idx}
      item={p}
      containerClassName={classes.productCardContainer}
      highlightTitle={title}
    />
  ));

  return (
    <div className={classes.wrapper}>
      <article className={classes.container}>
        <Carousel productCards={productCards} slidesPerView={2} breakpoints={breakpointsHighlight} />
      </article>
    </div>
  );
};

export { HighlightProducts };
